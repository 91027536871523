import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

 

import React ,{ Suspense, lazy } from "react"; // Added the missing import statement for the lazy function
import { ErrorBoundary } from "react-error-boundary";
 const NavBar = lazy(() => import("./components/NavBar")); // Updated the lazy function call
const Banner = lazy(() => import("./components/Banner")); // Updated the lazy function call
const Projects =  lazy(() => import("./components/Projects")); // Updated the lazy function call
const Footer =  lazy(() => import("./components/Footer")); // Updated the lazy function call
const Ourwork =  lazy(() => import("./components/Ourwork")); // Updated the lazy function call
const Ourteam =  lazy(() => import("./components/OurTeam")); // Updated the lazy function call
const Sponsors =  lazy(() => import("./components/Sponsors")); // Updated the lazy function call

function App() {
  return (
    <div className="App">
 <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <Suspense fallback={<></>}>
        <NavBar />
        
        <Banner />
        <Ourwork/>
        <Ourteam/>
        <Projects />
        <Sponsors/>
        <Footer />
      </Suspense>
      </ErrorBoundary>
    </div>
  );
}

export default App;
